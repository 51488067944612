import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { createSignal, Show } from "solid-js";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import "./SeoTextSolutions.css";

import IconExpandMore from "~/img/icons/expand_more.svg";

export default function SeoTextSolutions() {
  const [viewportSizeProvider] = useViewportSizeContext();

  const [currentActiveCard, setCurrentActiveCard] = createSignal(0);

  function changeActiveCard(cardPosition: number) {
    if (currentActiveCard() !== cardPosition) {
      setCurrentActiveCard(cardPosition);
    } else {
      setCurrentActiveCard(-1);
    }
  }

  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <div class="content-part block-seo-solutions" data-test="block-seo">
        <section class="solutions-container" id="solutions">
          <h2>
            Les solutions{" "}
            <span
              class="logo-avantages-svg"
              innerHTML={
                settingsContext.marketing_segments_terms.invest
                  .field_logo_main_svg
              }
            />
          </h2>
          <div class="solutions" data-test="solutions">
            <Show when={!viewportSizeProvider.viewPortIsLessThan1024}>
              <ul class="desktop-tabs" data-test="tabs">
                <li
                  onClick={() => changeActiveCard(0)}
                  classList={{ active: currentActiveCard() === 0 }}
                  data-test="lli"
                >
                  LLI
                </li>
                <li
                  onClick={() => changeActiveCard(1)}
                  classList={{ active: currentActiveCard() === 1 }}
                  data-test="lli-furnished"
                >
                  LLI Meublée
                </li>
                <li
                  onClick={() => changeActiveCard(2)}
                  classList={{ active: currentActiveCard() === 2 }}
                  data-test="patrimonial"
                >
                  Patrimoniale
                </li>
                <li
                  onClick={() => changeActiveCard(3)}
                  classList={{ active: currentActiveCard() === 3 }}
                  data-test="lmnp-vat-inc"
                >
                  Meublée
                </li>
                <li
                  onClick={() => changeActiveCard(4)}
                  classList={{ active: currentActiveCard() === 4 }}
                  data-test="lmnp-vat-ex"
                >
                  Meublée Gérée
                </li>
                <li
                  onClick={() => changeActiveCard(5)}
                  classList={{ active: currentActiveCard() === 5 }}
                  data-test="bare-ownership"
                >
                  Nue-Propriété
                </li>
              </ul>
            </Show>
            {/* LLI */}
            <div
              class="solution"
              data-test="solution-lli"
              classList={{ active: currentActiveCard() === 0 }}
            >
              <h3 onClick={() => changeActiveCard(0)}>
                LLI{" "}
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </h3>
              <div class="card">
                <div class="inner">
                  <div class="left-part">
                    <h4>
                      Investir durablement, en profitant d’une TVA réduite et
                      d’avantages fiscaux inédit pour une rentabilité
                      stable&nbsp;!
                    </h4>
                    <p>
                      Le Logement Locatif Intermédiaire (LLI) est une solution
                      idéale pour allier rentabilité et engagement sociétal. En
                      investissant dans un bien neuf Cogedim, vous profitez
                      d’une TVA réduite à 10&nbsp;% et d’un crédit d’impôt sur
                      la taxe foncière jusqu’à 20 ans.
                    </p>
                    <Show when={!viewportSizeProvider.viewPortIsLessThan768}>
                      <a
                        href="#residences-eligibles"
                        class="btn"
                        data-test="anchor"
                      >
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                  <div class="right-part">
                    <h5>
                      <span>Avantages exclusifs</span>
                    </h5>
                    <ul>
                      <li>Frais de notaire offerts</li>
                      <li>Création de SCI ou SARL de famille offerte</li>
                      <li>
                        1 an de gestion locative et de garantie loyer impayé
                        offert
                      </li>
                      <li>Cuisine offerte</li>
                    </ul>
                    <Show when={viewportSizeProvider.viewPortIsLessThan768}>
                      <a href="#residences-eligibles" class="btn">
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                </div>
              </div>
            </div>
            {/* LLI MEUBLE */}
            <div
              class="solution"
              data-test="solution-lli-furnished"
              classList={{ active: currentActiveCard() === 1 }}
            >
              <h3 onClick={() => changeActiveCard(1)}>
                LLI Meublée{" "}
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </h3>
              <div class="card">
                <div class="inner">
                  <div class="left-part">
                    <h4>
                      Cumulez les avantages du LLI et du meublé pour maximiser
                      votre investissement
                    </h4>
                    <p>
                      Avec la Solution LLI Meublée, vous combinez les bénéfices
                      du Logement Locatif Intermédiaire et de la Location
                      Meublée, en bénéficiant d’une TVA réduite, d’un crédit
                      d’impôt et d’un cadre fiscal favorable grâce à
                      l’amortissement du bien.
                    </p>
                    <Show when={!viewportSizeProvider.viewPortIsLessThan768}>
                      <a
                        href="#residences-eligibles"
                        class="btn"
                        data-test="anchor"
                      >
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                  <div class="right-part">
                    <h5>
                      <span>Avantages exclusifs</span>
                    </h5>
                    <ul>
                      <li>Mobilier et cuisine offerts</li>
                      <li>Création de SCI ou SARL de famille offerte</li>
                      <li>
                        1 an de gestion locative et de garantie loyer impayé
                        offert
                      </li>
                      <li>1 an d’accompagnement fiscal offert</li>
                    </ul>
                    <Show when={viewportSizeProvider.viewPortIsLessThan768}>
                      <a href="#residences-eligibles" class="btn">
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                </div>
              </div>
            </div>
            {/* PATRIMONIALE */}
            <div
              class="solution"
              data-test="solution-patrimonial"
              classList={{ active: currentActiveCard() === 2 }}
            >
              <h3 onClick={() => changeActiveCard(2)}>
                Patrimoniale{" "}
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </h3>
              <div class="card">
                <div class="inner">
                  <div class="left-part">
                    <h4>Idéal pour bâtir votre patrimoine</h4>
                    <p>
                      L’investissement patrimonial est la solution idéale pour
                      ceux qui souhaitent se constituer un patrimoine immobilier
                      et générer des revenus locatifs sécurisés une fois le
                      crédit remboursé. Avec la location nue, vous bénéficiez
                      d’une gestion simplifiée et d’un cadre fiscal avantageux.
                    </p>
                    <Show when={!viewportSizeProvider.viewPortIsLessThan768}>
                      <a
                        href="#residences-eligibles"
                        class="btn"
                        data-test="anchor"
                      >
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                  <div class="right-part">
                    <h5>
                      <span>Avantages exclusifs</span>
                    </h5>
                    <ul>
                      <li>Frais de notaire offerts</li>
                      <li>
                        1 an de gestion locative et de garantie loyer impayé
                        offert
                      </li>
                      <li>Cuisine offerte</li>
                    </ul>
                    <Show when={viewportSizeProvider.viewPortIsLessThan768}>
                      <a href="#residences-eligibles" class="btn">
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                </div>
              </div>
            </div>
            {/* MEUBLEE */}
            <div
              class="solution"
              data-test="solution-lmnp-vat-inc"
              classList={{ active: currentActiveCard() === 3 }}
            >
              <h3 onClick={() => changeActiveCard(3)}>
                Meublée{" "}
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </h3>
              <div class="card">
                <div class="inner">
                  <div class="left-part">
                    <h4>
                      Fiscalité avantageuse et des revenus optimisés, profitez
                      d’un investissement rentable avec le statut LMNP
                    </h4>
                    <p>
                      La location meublée permet de maximiser vos revenus
                      locatifs tout en bénéficiant d’un régime fiscal
                      avantageux. Grâce au statut LMNP, vous pouvez percevoir
                      des loyers nets d’impôt grâce à l’amortissement du bien et
                      à la déduction des charges.
                    </p>
                    <Show when={!viewportSizeProvider.viewPortIsLessThan768}>
                      <a
                        href="#residences-eligibles"
                        class="btn"
                        data-test="anchor"
                      >
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                  <div class="right-part">
                    <h5>
                      <span>Avantages exclusifs</span>
                    </h5>
                    <ul>
                      <li>Mobilier et cuisine offerts</li>
                      <li>
                        1 an de gestion locative et de garantie loyer impayé
                        offert
                      </li>
                      <li>1 an d’accompagnement fiscal offert</li>
                    </ul>
                    <Show when={viewportSizeProvider.viewPortIsLessThan768}>
                      <a href="#residences-eligibles" class="btn">
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                </div>
              </div>
            </div>
            {/* MEUBLEE GEREE */}
            <div
              class="solution"
              data-test="solution-lmnp-vat-ex"
              classList={{ active: currentActiveCard() === 4 }}
            >
              <h3 onClick={() => changeActiveCard(4)}>
                Meublée Gérée{" "}
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </h3>
              <div class="card">
                <div class="inner">
                  <div class="left-part">
                    <h4>
                      Un investissement sans gestion, sans souci pour des
                      revenus garantis
                    </h4>
                    <p>
                      Grâce à la Location Meublée Gérée, vous bénéficiez d’un
                      bail commercial sécurisé qui garantit le paiement des
                      loyers, même en cas de vacance locative. Investissez dans
                      une résidence étudiante, senior ou jeunes actifs et
                      profitez d’une fiscalité optimisée, et d’un placement
                      rentable et sans gestion.
                    </p>
                    <Show when={!viewportSizeProvider.viewPortIsLessThan768}>
                      <a
                        href="#residences-eligibles"
                        class="btn"
                        data-test="anchor"
                      >
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                  <div class="right-part">
                    <h5>
                      <span>Avantages exclusifs</span>
                    </h5>
                    <ul>
                      <li>Revenus garantis grâce au bail commercial</li>
                      <li>Déduction fiscale et amortissement du bien</li>
                      <li>Investissement clé en main sans gestion</li>
                    </ul>
                    <Show when={viewportSizeProvider.viewPortIsLessThan768}>
                      <a href="#residences-eligibles" class="btn">
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                </div>
              </div>
            </div>
            {/* NUE-PROPRIETE */}
            <div
              class="solution"
              data-test="solution-bare-ownership"
              classList={{ active: currentActiveCard() === 5 }}
            >
              <h3 onClick={() => changeActiveCard(5)}>
                Nue-Propriété{" "}
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </h3>
              <div class="card">
                <div class="inner">
                  <div class="left-part">
                    <h4>
                      Profitez d’un investissement à prix décoté pour investir
                      sur le long terme
                    </h4>
                    <p>
                      La nue-propriété est une solution d’investissement long
                      terme qui vous permet d’acquérir un bien avec une décote
                      jusqu’à 39%, sans gestion locative ni fiscalité pendant
                      toute la durée du démembrement. À terme, vous récupérez la
                      pleine propriété du bien et pouvez l’habiter, le louer ou
                      le revendre avec une forte valorisation.
                    </p>
                    <Show when={!viewportSizeProvider.viewPortIsLessThan768}>
                      <a
                        href="#residences-eligibles"
                        class="btn"
                        data-test="anchor"
                      >
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                  <div class="right-part">
                    <h5>
                      <span>Avantages exclusifs</span>
                    </h5>
                    <ul>
                      <li>Des biens de qualité</li>
                      <li>Idéal pour la transmission avec des frais réduits</li>
                      <li>Un investissement à prix réduit</li>
                      <li>Aucune contrainte de gestion</li>
                    </ul>
                    <Show when={viewportSizeProvider.viewPortIsLessThan768}>
                      <a href="#residences-eligibles" class="btn">
                        Découvrir les programmes éligibles
                      </a>
                    </Show>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
